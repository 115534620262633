import campaign from "@/lib/prismicio/prismic-campaign.json";
import { useI18n, useNewsletter } from "@jog/react-components";
import { localStorage } from "@jog/shared";
import { AlertBox, Button, Input, LogoLoader } from "components";
import { useMemo } from "react";
export const NewsForm = ({ source }) => {
    const newsletterUrl = useMemo(() => campaign === null || campaign === void 0 ? void 0 : campaign.defaultCampaign, []);
    const { $t } = useI18n();
    const { handleEmailChange, handleFormSubmission, errorMessage, invalidEmailError, isLoading, emailInput, isButtonDisabled, emailInputId, } = useNewsletter({
        source,
        ls: localStorage,
        newsletterUrl,
    });
    return (<form className="relative flex min-w-full flex-col items-baseline lg:min-w-0 lg:flex-1" onSubmit={(e) => e.preventDefault()}>
            {isLoading && <LogoLoader />}
            {errorMessage && <AlertBox isError={true} text={$t(errorMessage)}/>}
            <div className="flex w-full flex-wrap xl:flex-nowrap">
                <Input className="!mt-3 !h-[42px] w-full !border border-gray-silver !text-left font-primary placeholder:text-gray-silver md:!m-0 md:w-1/2 lg:w-auto" aria-label="email" value={emailInput} name="email" type="email" onChange={handleEmailChange} placeholder={$t("E-mail address")} id={emailInputId} required/>

                <div className="flex w-full md:w-auto">
                    <Button fontWeight="600" className="!mt-3 w-full  !py-3 sm:!w-auto md:!m-0" onClick={handleFormSubmission} disabled={isButtonDisabled || !emailInput}>
                        {$t("Subscribe")}
                    </Button>
                </div>
            </div>
            {invalidEmailError && <div className="mt-2 block w-full text-sm">{$t(invalidEmailError)}</div>}
        </form>);
};
