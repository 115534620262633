import { useSocial } from "@/hooks/useSocial";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import metaJson from "@/lib/prismicio/prismic-meta.json";
import storelocatorJson from "@/lib/prismicio/prismic-storelocator.json";
import { checkForIOS, getDomain } from "@jog/shared";
import { asImageSrc } from "@prismicio/helpers";
import NextHead from "next/head";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
const preconnectEndpointList = () => {
    return [
        `${process.env.NEXT_PUBLIC_CTP_ENDPOINT}`,
        "https://dev.visualwebsiteoptimizer.com",
        "https://res.cloudinary.com",
        "https://connect.facebook.net",
        "https://www.facebook.com",
        "https://fonts.googleapis.com",
        "https://www.google-analytics.com",
        "https://www.google.com",
        "https://www.googletagmanager.com",
        "https://www.gstatic.com",
        "https://in.hotjar.com",
        "https://stats.g.doubleclick.net",
        "https://h.clarity.ms/",
        "https://images.prismic.io",
        `https://${process.env.NEXT_PUBLIC_PRISMIC_REPO}.prismic.io`,
        `https://${process.env.NEXT_PUBLIC_PRISMIC_REPO}.cdn.prismic.io`,
    ];
};
const domain = getDomain();
const organizationLogo = (logo, socialUrls, title) => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": `${domain}/#Organization`,
    url: `${domain}/`,
    name: title,
    logo: logo,
    sameAs: socialUrls,
});
const productSD = (productStructuredData) => {
    if (!domain)
        return null;
    if (!productStructuredData)
        return null;
    return {
        "@context": "https://schema.org",
        "@type": "Product",
        "@id": `${domain}/#Product`,
        name: productStructuredData.name,
        image: productStructuredData.images,
        offers: {
            "@type": "Offer",
            price: productStructuredData.price,
            priceCurrency: productStructuredData.currency,
            itemCondition: "https://schema.org/UsedCondition",
            availability: "https://schema.org/InStock",
        },
        description: productStructuredData.description,
        color: productStructuredData.color,
        brand: productStructuredData.brand,
        sku: productStructuredData.sku,
        size: productStructuredData.size,
        material: productStructuredData.material,
    };
};
const localBusinessSD = (localBusinessData, path) => {
    if (!localBusinessData)
        return null;
    if (!(path === null || path === void 0 ? void 0 : path.includes(localBusinessData.storeUrl)))
        return null;
    if (!domain)
        return null;
    return {
        "@context": "https://schema.org",
        "@graph": localBusinessData.stores.map((item, index) => {
            var _a;
            return ({
                "@type": "Store",
                "@id": `${domain}/#ClothingStore/${index}`,
                name: item.storeTitle,
                address: {
                    "@type": "PostalAddress",
                    streetAddress: item.street,
                    addressLocality: item.city,
                    addressRegion: item.country,
                    postalCode: item.postalCode,
                    addressCountry: item.country,
                },
                telephone: item.phone,
                url: `${domain}/${localBusinessData.storeUrl}`,
                image: item.image,
                openingHoursSpecification: (_a = item.openingHours.map((item) => ({
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: item.day,
                    opens: item.open,
                    closes: item.close,
                }))) !== null && _a !== void 0 ? _a : "",
            });
        }),
    };
};
export const Head = ({ title = null, description = null, robots = null, isHomePage = false, pathname, productStructuredData, alternate, }) => {
    var _a;
    const meta = useMemo(() => metaJson, []);
    const localBusinessData = useMemo(() => storelocatorJson, []);
    const layout = useLayout();
    const router = useRouter();
    const _pathname = useMemo(() => {
        if (pathname)
            return pathname;
        const { asPath, defaultLocale, locale, query } = router;
        const path = query["pretty-path"] || asPath; // start with `/`
        return defaultLocale === locale ? `${path}` : `/${locale}${path}`; // start with `/`
    }, [pathname, router]);
    const currentPath = useMemo(() => `${domain}${_pathname}`, [_pathname]);
    const socials = useSocial();
    const organizationJSON = useMemo(() => {
        var _a;
        if (typeof window !== "undefined" && window.location.pathname === "/") {
            return JSON.stringify(organizationLogo(asImageSrc((_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo), socials.map((i) => i.href), meta.structured_data_organization_title));
        }
        return null;
    }, [(_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo, meta.structured_data_organization_title, socials]);
    const productStructuredDataJSON = useMemo(() => {
        const data = productSD(productStructuredData);
        return data ? JSON.stringify(data) : null;
    }, [productStructuredData]);
    const localBusinessDataJSON = useMemo(() => {
        const data = localBusinessSD(localBusinessData, router.asPath);
        return data ? JSON.stringify(data) : null;
    }, [localBusinessData, router.asPath]);
    const [viewport, setViewPort] = useState("width=device-width, initial-scale=1, maximum-scale=1");
    useEffect(() => {
        const isIos = checkForIOS();
        !isIos && setViewPort("width=device-width");
    }, []);
    return (<>
            <NextHead>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
                <title>{title || meta.title || "Welcome to GARCIA"}</title>
                {alternate === null || alternate === void 0 ? void 0 : alternate.map(({ locale, url }) => (<link rel="alternate" href={url} key={"alternate" + locale + url} hrefLang={locale}/>))}
                <link rel="canonical" href={currentPath}/>
                {process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION && (<meta name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION}/>)}

                <meta name="viewport" content={viewport}/>
                <meta name="description" content={description || meta.description || "Welcome to GARCIA"}/>
                <meta name="robots" content={process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
            ? robots || meta.robots || "index,follow"
            : "noindex, nofollow"}/>
                <meta property="og:title" content={meta.og_title || "GARCIA Official webshop | The fashion brand with a denim heart"}/>
                <meta property="og:site_name" content={meta.og_site_name || `${domain}`}/>
                <meta property="og:image" content={meta.og_image ||
            "https://res.cloudinary.com/jeanscentre/image/fetch/f_auto,q_auto:good/https://images.prismic.io/garcia-acceptance/74538281-e390-4ee5-ad56-4bd8617466e6_logo-garcia.png"}/>
                <meta property="og:description" content={meta.og_description ||
            "GARCIA: A brand with a denim heart with the latest fashion items for young and old | Get inspired by our new collection and shop your GARCIA look online."}/>

                {isHomePage && <meta name="facebook-domain-verification" content="o8mscvc5og6nujhx6puki2954cp91f"/>}
                {preconnectEndpointList().map((item, index) => (<link rel="preconnect" href={item} crossOrigin="anonymous" key={index}/>))}
                {organizationJSON && <script type="application/ld+json">{organizationJSON}</script>}
                {productStructuredDataJSON && <script type="application/ld+json">{productStructuredDataJSON}</script>}
                {localBusinessDataJSON && <script type="application/ld+json">{localBusinessDataJSON}</script>}
            </NextHead>
        </>);
};
